<template>
  <ModalBox size="max" :hideClose="true">
    <template #content>
      <div class="modal__content_title">Правила акции</div>
      <div v-if="isAndroid" class="modal__content_pdf_link">
        <a :href="pdfUrl" target="_blank">Ознакомиться с Правилами акции</a>
      </div>
      <div v-else class="modal__content_pdf">
        <object :data="pdfUrl" type="application/pdf" width="100%" height="600px">
          <iframe :src="`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`" style="width: 100%; height: 600px"></iframe>
        </object>
      </div>

      <div class="modal__content_checkbox">
        <CheckBox name="termsAgreement" v-model="agreement" text="Я согласен с правилами акции" />
      </div>
      <div class="modal__content_button modal__content_button_center">
        <MainButton title="Принять участие" :isDisabled="!agreement" @click="acceptAgreement" color="red" />
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import CheckBox from "@/components/ui/Checkbox/CheckBox.vue";

export default {
  name: "TermsModal",
  components: {
    ModalBox,
    MainButton,
    CheckBox,
  },
  data() {
    return {
      agreement: false,
    };
  },
  computed: {
    userRole() {
      return this.$store.getters["user/GET_USER_ROLE"];
    },
    pdfUrl() {
      if (this.userRole === "curator") {
        return "//liga.starateli.ru/upload/static/Pravila_Partnera.pdf";
      } else if (this.userRole === "manager") {
        return "//liga.starateli.ru/upload/static/Pravila_Menedzher_po_prodazham_Partnera.pdf";
      } else if (this.userRole === "member") {
        return "//liga.starateli.ru/upload/static/Pravila_Klient_Partnera.pdf";
      }
      return "";
    },
    isAndroid() {
      return /android/i.test(navigator.userAgent) ? true : false;
    },
  },
  methods: {
    async acceptAgreement() {
      let res = await this.$api.user.acceptAgreement();
      if (res.isSuccess) {
        this.$store.dispatch("user/fetchUser");
        this.$emit("hideTerms");
      }
    },
  },
};
</script>
