<template>
  <ModalBox :hideClose="true">
    <template #content>
      <div class="modal__content_title">Введите код подтверждения</div>
      <div v-if="error" class="modal__content_error">
        {{ error }}
      </div>
      <div class="modal__content_text">
        На ваш номер телефона отправлен код подтверждения. Он придет в течение одной минуты. После его ввода на ваш электронный адрес
        поступит логин и пароль для входа на сайт. Если sms не поступит, обратитесь на почту обратной связи liga@starateli.com
      </div>
      <div class="modal__content_otp">
        <InputCode v-model="activation.code" />
      </div>
      <div class="modal__content_button modal__content_button_center">
        <MainButton title="Активировать" color="red" :isDisabled="!(activation.code && activation.code.length === 4)" @click="activate" />
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import InputCode from "@/components/ui/Input/InputCode.vue";

export default {
  name: "ActivationModal",
  components: {
    ModalBox,
    MainButton,
    InputCode,
  },
  props: {
    form: Object,
  },
  data() {
    return {
      activation: {
        code: null,
      },
      error: null,
    };
  },
  methods: {
    async activate() {
      let payload = this.form;
      payload["confirmation_code"] = this.activation.code;
      let res = await this.$api.user.register(payload);
      if (res.isSuccess) {
        this.$emit("hideModal");
        this.$emit("openWarning");
        this.resetForm();
      } else {
        this.error = res.error.getMessage();
      }
    },
    resetForm() {
      this.activation.code = null;
    },
  },
};
</script>
