<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_success">
        <div class="modal__content_success_icon">
          <img src="@/assets/img/check-green.svg" />
        </div>
        <div class="modal__content_success_text" v-html="text"></div>
      </div>
      <div class="modal__content_button">
        <MainButton title="ОК" color="red" @click="$emit('hideModal')" />
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "ModalSuccess",
  components: {
    ModalBox,
    MainButton,
  },
  props: {
    text: String,
  },
};
</script>
