import network from "@/api/network";

export default class General {
  static async getCompaniesList(data) {
    return await network.get("user/curator/companies/find/full_list", data, {});
  }

  static async getCompanyByTin(tin) {
    return await network.get(`company/find/information/${tin}`, null, {});
  }

  static async getCompanyByName(name) {
    return await network.get(`user/curator/companies/find/${name}`, null, {});
  }

  static async getCityList(query) {
    return await network.get("/prize/delivery/address/suggest", query, {});
  }

  static async getStreetList(query) {
    return await network.get("/prize/delivery/address/suggest", query, {});
  }

  static async getManagerByPhone(phone) {
    return await network.get(`user/manager/phone/${phone}`, null, {});
  }

  static async getSegments() {
    return await network.get("segment/list", null, {});
  }

  static async setCustomSegment(data) {
    return await network.post("segment/custom", data, {});
  }

  static async getRetailByTin(data) {
    return await network.get(`user/company/`, data, {});
  }
}
