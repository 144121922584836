import network from "@/api/network";

export default class Invite {
  static async getInviteList() {
    return await network.post("invites/list/user/current", null, {});
  }

  static async getRequestList() {
    return await network.get("requests/registrations", null, {});
  }

  static async approveRequest(data) {
    return await network.post("requests/registrations/accept", data, {});
  }

  static async denyRequest(data) {
    return await network.post("requests/registrations/revoke", data, {});
  }

  static async exportInviteList(config) {
    return await network.post("invites/list/user/current/download", null, config);
  }

  static async inviteAllUsers() {
    return await network.post("invites/user/current/resend/all", null, {});
  }

  static async inviteSingleUser(data) {
    return await network.post("invites/user/current/resend", data, {});
  }

  static async inviteManually(data) {
    return await network.post("invites", data, {});
  }

  static async inviteAutomatically(data) {
    return await network.post("invites/import", data, {});
  }

  static async getInviteInfo(id) {
    return await network.get(`invites/${id}`, null, {});
  }

  static async getReferralInfo(code) {
    return await network.get(`referral/${code}`, null, {});
  }
}
