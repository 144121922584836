<template>
  <div>
    <div class="tab-modal__overlay" @click="$emit('hideModal')"></div>
    <div class="tab-modal">
      <TabContainer @click="$emit('reset')">
        <TabItem title="Клиент" :isModal="true" v-if="role === 'member' || showBoth">
          <div class="tab-modal__header">
            <slot name="header" />
            <div class="tab-modal__header_button">
              <MainButton :iconAfter="require('@/assets/img/close.svg')" @click="$emit('hideModal')" />
            </div>
          </div>
          <div class="tab-modal__content">
            <slot name="content-representative" />
          </div>
        </TabItem>
        <TabItem title="Менеджер по продажам" :isModal="true" v-if="role === 'manager' || showBoth">
          <div class="tab-modal__header">
            <slot name="header" />
            <div class="tab-modal__header_button">
              <MainButton :iconAfter="require('@/assets/img/close.svg')" @click="$emit('hideModal')" />
            </div>
          </div>
          <div class="tab-modal__content">
            <slot name="content-sales" />
          </div>
        </TabItem>
      </TabContainer>
    </div>
  </div>
</template>

<script>
import TabContainer from "@/components/ui/Tabs/TabContainer.vue";
import TabItem from "@/components/ui/Tabs/TabItem.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "TabModal",
  components: {
    TabContainer,
    TabItem,
    MainButton,
  },
  props: {
    showBoth: {
      type: Boolean,
      default: false,
    },
    role: String,
  },
};
</script>

<style lang="scss">
.tab-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  max-width: 640px;
  width: 100%;
  max-height: 98vh;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.06);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: $transition-main;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(16, 24, 32, 0.5);
    transition: $transition-main;
    z-index: 10;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__header_button {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
      transition: $transition-main;

      &:hover {
        transform: rotate(-180deg) scale(1.2);
        transition: $transition-main;
        background-color: transparent;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 0 34px 34px 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-grey-50;

    &_title {
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: 700;
    }

    &_subtitle {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 700;
    }

    &_form {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &_inputs {
        display: flex;
        gap: 20px;

        &.text {
          color: $color-grey-30;
        }
      }

      &_input {
        max-width: 250px;
        width: 100%;
      }

      &_button {
        max-width: 250px;
        width: 100%;
        height: 48px;
      }

      &_section {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &_subtitle {
        font-size: 14px;
        font-weight: 600;
      }
    }

    &_error {
      color: $color-red-20;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 20px;
    }

    &_notification {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      align-items: flex-start;

      & p {
        margin-bottom: 10px;
        @include font("Inter", 14px, 600);
      }
    }

    &_button {
      width: 240px;
      height: 58px;

      &_center {
        align-self: center;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .tab-modal {
    max-width: 560px;

    &__content {
      padding: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .tab-modal {
    max-width: 480px;

    &__content {
      &_form {
        &_inputs {
          flex-wrap: wrap;
          gap: 10px;
        }

        &_button {
          max-width: 100%;
        }
      }

      &_button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .tab-modal {
    max-width: 420px;
  }
}

@media screen and (max-width: 420px) {
  .tab-modal {
    max-width: 314px;
  }
}
</style>
