<template>
  <div class="header">
    <div class="header__container">
      <div class="header__logo">
        <router-link to="/" @click="scrollToTop">
          <img src="@/assets/img/logo.svg" />
        </router-link>
      </div>
      <div class="header__menu header__menu_desktop">
        <MenuLink v-for="link in menu" :key="link.id" :route="link.link" :title="link.name" />
        <div class="header__menu_rules">
          <DownloadLink v-if="isAuth" title="Правила участника" :href="promoRules" color="transparent-white" />
        </div>
      </div>
      <div class="header__button">
        <div v-if="isAuth" class="header__balance">
          <div class="header__balance_icon">
            <img src="@/assets/img/logo-coins.svg" />
          </div>
          <div class="header__balance_sum">
            <div>{{ userBalance }}</div>
            <div>{{ balanceDisplay }}</div>
          </div>
        </div>
        <div v-if="isAuth" class="header__button_button header__button_desktop">
          <MainButton title="Выйти" color="white" :iconBefore="require('@/assets/img/login.svg')" @click="$emit('logout')" />
        </div>
        <div v-else class="header__button_button header__button_desktop">
          <MainButton title="Войти" color="white" :iconBefore="require('@/assets/img/login.svg')" @click="$emit('login')" />
        </div>
      </div>
      <div class="header__mobile_button">
        <MenuButton @click="toggleMenu" />
      </div>
      <div id="burger-menu" class="header__mobile_menu">
        <div class="header__mobile_menu_list">
          <MenuLink v-for="link in menu" :key="link.id" :route="link.link" :title="link.name" color="black" @click="toggleMenu" />
          <DownloadLink v-if="isAuth" title="Правила участника" :href="promoRules" color="black-menu" />
        </div>
        <div class="header__mobile_menu_buttons">
          <div v-if="isAuth" class="header__mobile_menu_button">
            <MainButton title="Выйти" color="red" :iconBefore="require('@/assets/img/login-white.svg')" @click="logout" />
          </div>
          <div v-else class="header__mobile_menu_button">
            <MainButton title="Войти" color="red" :iconBefore="require('@/assets/img/login-white.svg')" @click="login" />
          </div>
          <div v-if="!isAuth" class="header__mobile_menu_button">
            <MainButton title="Зарегистрироваться" color="transparent-red" @click="emitClick('register')" />
          </div>
          <MainButton v-if="!isAuth" title="Забыли пароль?" color="transparent" @click="emitClick('password')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/ui/Button/MainButton.vue";
import MenuLink from "@/components/ui/Link/MenuLink.vue";
import DownloadLink from "@/components/ui/Link/DownloadLink.vue";
import MenuButton from "@/components/ui/Button/MenuButton.vue";

export default {
  name: "HeaderBar",
  components: {
    MainButton,
    MenuLink,
    DownloadLink,
    MenuButton,
  },
  data() {
    return {
      showMenu: false,
      menuPublic: [
        {
          id: Math.random().toString(16).slice(2),
          link: "/",
          name: "Главная",
        },
        {
          id: Math.random().toString(16).slice(2),
          link: "/rules/",
          name: "Правила программы",
        },
        {
          id: Math.random().toString(16).slice(2),
          link: "/faq/",
          name: "Вопрос-ответ",
        },
        // {
        //   id: Math.random().toString(16).slice(2),
        //   link: "/learning/",
        //   name: "Обучение",
        // },
        // {
        //   id: Math.random().toString(16).slice(2),
        //   link: "/news/",
        //   name: "Новости",
        // },
      ],
      menuCurator: [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/report/",
          name: "Загрузить отчет",
        },
        {
          link: "/dashboard/invite/",
          name: "Приглашения",
        },
        {
          link: "/dashboard/points/",
          name: "Мои баллы",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
      ],
      menuSales: [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/sales/",
          name: "Мои продажи",
        },
        {
          link: "/dashboard/invite/",
          name: "Приглашения",
        },
        {
          link: "/dashboard/points/",
          name: "Мои баллы",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
      ],
      menuMember: [
        {
          link: "/dashboard/profile/",
          name: "Профиль",
        },
        {
          link: "/dashboard/purchase/",
          name: "Мои покупки",
        },
        {
          link: "/dashboard/points/",
          name: "Мои баллы",
        },
        {
          link: "/dashboard/prize/",
          name: "Подарки",
        },
      ],
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["user/GET_IS_AUTH"];
    },
    userRole() {
      return this.$store.getters["user/GET_USER_ROLE"];
    },
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
    userBalance() {
      if (this.userProfile && this.userProfile.points) {
        return Math.floor(this.userProfile.points.points)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
      return "";
    },
    balanceDisplay() {
      if (this.userBalance) {
        let titles = ["балл", "балла", "баллов"];
        let number = Number(this.userBalance);
        let cases = [2, 0, 1, 1, 1, 2];
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
      }
      return null;
    },
    menu() {
      if (this.isAuth) {
        if (this.userRole === "curator") {
          return this.menuCurator;
        } else if (this.userRole === "manager") {
          return this.menuSales;
        } else if (this.userRole === "member") {
          return this.menuMember;
        }
      }
      return this.menuPublic;
    },
    promoRules() {
      if (this.userRole === "curator") {
        return "//liga.starateli.ru/upload/static/Pravila_Partnera.pdf";
      } else if (this.userRole === "manager") {
        return "//liga.starateli.ru/upload/static/Pravila_Menedzher_po_prodazham_Partnera.pdf";
      } else if (this.userRole === "member") {
        return "//liga.starateli.ru/upload/static/Pravila_Klient_Partnera.pdf";
      }
      return null;
    },
  },
  methods: {
    scrollToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      document.getElementById("burger-menu").classList.toggle("header__mobile_menu_active");
      document.getElementById("burger-icon").classList.toggle("open");
    },
    emitClick(emit) {
      this.toggleMenu();
      this.$emit(`${emit}`);
    },
    logout() {
      this.toggleMenu();
      this.$emit("logout");
    },
    login() {
      this.toggleMenu();
      this.$emit("login");
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 86px;
  position: fixed;
  background-color: #e22b2a;
  z-index: 4;

  &__container {
    margin: 0 auto;
    padding: 16px 28px;
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu {
    display: flex;
    gap: 30px;
  }

  &__balance {
    display: flex;
    gap: 14px;

    &_sum {
      color: $color-white;

      & div:first-child {
        font-size: 18px;
        font-weight: 500;
      }

      & div:last-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 35px;
    &_button {
      width: 121px;
      height: 48px;
    }
  }

  &__mobile {
    &_button {
      display: none;
    }

    &_menu {
      display: none;
      visibility: hidden;
      width: 320px;
      position: absolute;
      top: 86px;
      right: -322px;
      background-color: $color-white;
      padding: 22px 16px 32px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      transition: $transition-main;
      z-index: 4;

      &_active {
        visibility: visible;
        right: 0;
      }

      &_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // align-items: flex-end;
        justify-content: flex-start;
        gap: 40px;
        margin-top: 22px;
        margin-bottom: 80px;
      }

      &_buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }

      &_button {
        width: 100%;
        height: 58px;
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .header {
    &__menu {
      &_desktop {
        display: none;
      }
    }

    &__balance {
      &_icon img {
        width: 36px;
        height: 36px;
      }
      &_sum {
        & div:first-child {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    &__button {
      margin-left: auto;
      margin-right: 25px;
      &_desktop {
        display: none;
      }
    }

    &__mobile {
      &_button {
        display: block;
      }
    }
  }
}
</style>
