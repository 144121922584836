import network from "@/api/network";

export default class Profile {
  static async exportMembers(config) {
    return await network.get("user/profile/export/members", null, config);
  }

  static async exportManagers(config) {
    return await network.get("user/profile/export/managers", null, config);
  }

  static async addMemberCompany(data) {
    return await network.post("user/profile/add/company", data, {});
  }

  static async addMemberCurator(data) {
    return await network.post("user/profile/add/relation", data, {});
  }
}
