<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__web">
        <div class="footer__web_social">
          <a href="https://vk.com/starateli_ru" target="_blank">
            <img src="@/assets/img/vk.svg" />
          </a>
          <a href="https://t.me/starateli_company" target="_blank">
            <img src="@/assets/img/telegram.svg" />
          </a>
          <a href="https://ok.ru/staratelimsk" target="_blank">
            <img src="@/assets/img/ok.svg" />
          </a>
        </div>
        <div class="footer__web_website">
          Официальный сайт <br />
          <a href="https://www.starateli.ru/" target="_blank">starateli.ru</a>
        </div>
      </div>
      <div class="footer__contacts">
        <div class="footer__contacts_phone">8 800 770 72 65</div>
        <div class="footer__contacts_support">
          <div class="footer__contacts_support_title">Техническая поддержка</div>
          <div class="footer__contacts_support_button">
            <MainButton title="Написать" color="red" :iconBefore="require('@/assets/img/mail.svg')" @click="$emit('openFeedback')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "FooterBar",
  components: {
    MainButton,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: auto;
  margin-bottom: 0;
  width: 100%;
  background-color: $color-white;

  &__container {
    margin: 0 auto;
    padding: 60px 28px 54px;
    max-width: 1280px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    flex-wrap: wrap;
  }

  &__web {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;

    &_website {
      color: #37323d;
      font-size: 16px;
      font-weight: 400;
      align-self: flex-end;
      width: 100%;

      & a {
        color: #e22b2a;
        text-decoration: underline;
        font-weight: 500;
      }
    }

    &_social {
      display: flex;
      gap: 16px;
    }
  }

  &__contacts {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 28px;

    &_phone {
      color: $color-black;
      font-size: 24px;
    }

    &_support {
      &_title {
        margin-bottom: 16px;
        color: $color-black;
        font-size: 16px;
      }

      &_button {
        width: 220px;
        height: 52px;
      }
    }
  }
}

@media screen and (max-width: 463px) {
  .footer {
    &__container {
      justify-content: center;
    }

    &__web {
      gap: 22px;

      &_website {
        text-align: center;
      }
    }

    &__contacts {
      &_support {
        &_title {
          text-align: center;
        }
      }
    }
  }
}
</style>
