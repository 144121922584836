<template>
  <router-link v-if="route" :to="route" class="link" :class="setColor" @click.native="$emit('click')">
    {{ title }}
  </router-link>
</template>

<script>
export default {
  name: "MenuLink",
  props: {
    route: String,
    title: String,
    color: String,
  },
  computed: {
    setColor() {
      return `link_${this.color}`;
    },
  },
};
</script>

<style lang="scss">
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  cursor: pointer;
  width: fit-content;
  @include font("FFGoodPro", 18px, 400);
  color: $color-white;

  &.router-link-exact-active {
    padding-bottom: 5px;
    margin-top: 7px;
    border-bottom: 2px solid $color-white;
  }

  &_black {
    color: $color-black;
    font-weight: 500;

    &.router-link-exact-active {
      color: $color-red-20;
      padding-bottom: 0;
      margin-top: 0;
      border-bottom: none;
    }
  }
}
</style>
