<template>
  <div class="input-code">
    <input
      class="input-code__box"
      v-model="inputs[idx]"
      @keyup="inputFocus"
      @focus="check($event)"
      @change="handleChange(idx, $event)"
      v-for="(inp, idx) in inputCount"
      :key="idx"
      :tabindex="idx"
      maxlength="1"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  name: "InputCode",
  props: {
    inputCount: {
      type: Number,
      default: 4,
    },
    value: [String, Number, Object],
  },
  data() {
    return {
      inputs: Array(this.inputCount),
      currentKey: 0,
    };
  },
  watch: {
    value() {
      this.inputs = this.value.split("");
    },
  },
  methods: {
    handleChange(key, event) {
      this.inputs[key] = event.target.value;
      this.$emit("change", this.inputs);
    },

    getChildren(event) {
      return event.target.parentElement.children;
    },

    check(event) {
      const inputs = this.getChildren(event);

      if (inputs[this.currentKey]) {
        inputs[this.currentKey].focus();
      }
    },

    inputFocus(event) {
      const data = this.inputs.join("");
      const inputs = this.getChildren(event);

      this.$emit("input", data);
      if (data.length === this.inputCount) {
        this.$emit("completed", data);
      }

      if (event.key === "Delete" || event.key === "Backspace") {
        this.currentKey--;
        if (this.currentKey <= 0) {
          this.currentKey = 0;
        }
        if (inputs[this.currentKey]) {
          inputs[this.currentKey].focus();
        }
      } else {
        this.currentKey++;
        if (this.currentKey >= this.inputCount) {
          this.currentKey = this.inputCount;
        }
        if (inputs[this.currentKey]) {
          inputs[this.currentKey].focus();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-code {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &__box {
    width: 48px;
    height: 48px;
    border: 1px solid #e2e3e3;
    border-radius: 10px;
    text-align: center;
  }
}
</style>
