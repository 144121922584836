import network from "@/api/network";

export default class Content {
  static async getMenu() {
    return await network.get("content/menu", null, {});
  }

  static async getSteps() {
    return await network.get("content/steps", null, {});
  }

  static async getGifts() {
    return await network.get("content/gifts", null, {});
  }

  static async getRules() {
    return await network.get("content/rules", null, {});
  }

  static async getFAQ() {
    return await network.get("content/faq", null, {});
  }

  static async getInfo() {
    return await network.get("content/info", null, {});
  }

  static async sendFeedback(data) {
    return await network.post("content/feedback", data, {});
  }
}
