import api from "@/api";

const state = {
  prizeList: [],
  certificates: [],
  characteristics: [],
  orderInfo: null,
};

const getters = {
  GET_PRIZE_LIST(state) {
    return state.prizeList;
  },
  GET_CERTIFICATES(state) {
    return state.certificates;
  },
  GET_CHARACTERISTICS(state) {
    return state.characteristics;
  },
  GET_ORDER_INFO(state) {
    return state.orderInfo;
  },
};

const mutations = {
  SET_PRIZE_LIST(state, payload) {
    state.prizeList = payload;
  },
  SET_CERTIFICATES(state, payload) {
    state.certificates = payload;
  },
  SET_CHARACTERISTICS(state, payload) {
    state.characteristics = payload;
  },
  SET_ORDER_INFO(state, payload) {
    state.orderInfo = payload;
  },
};

const actions = {
  async getPrizeList({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.prize.getPrizeList();
    if (res.isSuccess) {
      // if (res.result.certificates.length > 0) {
      res.result.items.push({
        name: "Подарочный сертификат",
        description: "Выберите сертификат и номинал для получения приза",
        image_url: require("@/assets/img/certificate-prize.png"),
        handler_system_name: "giftery",
      });
      // }
      commit("SET_PRIZE_LIST", res.result.items);
      commit("SET_CERTIFICATES", res.result.certificates);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async getCharacteristics({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.prize.getCharacteristics();
    if (res.isSuccess) {
      commit("SET_CHARACTERISTICS", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  updateOrderInfo({ commit }, payload) {
    commit("SET_ORDER_INFO", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
