<template>
  <a class="download-link" :class="getColor" :href="href" target="_blank">
    <div class="download-link__inner">
      <div v-if="iconBefore" class="download-link__icon">
        <img :src="iconBefore" />
      </div>
      <div class="download-link__text">
        {{ title }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "DownloadLink",
  props: {
    iconBefore: {
      type: String,
    },
    title: {
      type: String,
    },
    href: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  computed: {
    getColor() {
      return `download-link_${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  transition: $transition-main;
  font-family: inherit;

  &_red {
    color: $color-white;
    background-color: $color-red-20;
    font-weight: 400;
    font-size: 17px;

    &:hover {
      background-color: $color-red-10;
    }
  }

  &_transparent-white {
    @include font("FFGoodPro", 18px, 400);
    color: $color-white;
    line-height: 14px;
    cursor: pointer;
  }

  &_white {
    color: $color-black;
    background-color: $color-white;
    font-weight: 400;
    font-size: 14px;
  }

  &_white-border {
    color: $color-black;
    background-color: $color-white;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #eeefef;
  }

  &_black-menu {
    color: $color-black;
    background-color: $color-white;
    line-height: 14px;
    font-size: 18px;
    font-weight: 500;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
