import { render, staticRenderFns } from "./MainInput.vue?vue&type=template&id=b05736cc&scoped=true&"
import script from "./MainInput.vue?vue&type=script&lang=js&"
export * from "./MainInput.vue?vue&type=script&lang=js&"
import style0 from "./MainInput.vue?vue&type=style&index=0&id=b05736cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b05736cc",
  null
  
)

export default component.exports