<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_title">Авторизация</div>
      <div v-if="error" class="modal__content_error">
        {{ error }}
      </div>
      <div class="modal__content_form modal__content_form_sm">
        <MainInput type="tel" v-model="loginForm.login" placeholder="Логин" name="login" id="login" :formatPhone="true" />
        <MainInput type="password" v-model="loginForm.password" placeholder="Пароль" name="password" id="password" />
      </div>
      <div class="modal__content_button">
        <MainButton title="Войти" color="red" :isDisabled="!(loginForm.login && loginForm.password)" @click="login" />
      </div>
      <div class="modal__content_options">
        <div>
          <MainButton title="Забыли пароль?" color="transparent" @click="$emit('toRecovery')" />
        </div>
        <div>
          <MainButton title="Зарегистрироваться" color="transparent" @click="$emit('toRegister')" />
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import MainInput from "@/components/ui/Input/MainInput.vue";

export default {
  name: "LoginForm",
  components: {
    ModalBox,
    MainButton,
    MainInput,
  },
  data() {
    return {
      loginForm: {
        login: null,
        password: null,
      },
    };
  },
  computed: {
    error() {
      return this.$store.getters["user/GET_ERROR"];
    },
    isAuth() {
      return this.$store.getters["user/GET_IS_AUTH"];
    },
  },
  methods: {
    async login() {
      let payload = {
        login: this.loginForm.login.replace(/[^A-Z0-9]/gi, ""),
        password: this.loginForm.password,
      };
      await this.$store.dispatch("user/login", payload);
      if (this.isAuth && !this.error) {
        this.resetForm();
        this.$emit("hideModal");
        this.$router.push("/dashboard/profile/");
      }
    },
    resetForm() {
      this.loginForm.login = this.loginForm.password = null;
    },
  },
};
</script>
