import utils from "@/assets/js/utils";

class ApiError {
  value = null;

  get isArray() {
    return utils.isArray(this.value);
  }

  get isObject() {
    return utils.isPlainObject(this.value);
  }

  constructor(data) {
    if (typeof data === "string" && data !== "") {
      this.value = [data];
    } else if (utils.isArray(data) && data.length > 0) {
      this.value = data;
    } else if (utils.isPlainObject(data)) {
      let value = {},
        hasValue = false;
      for (let key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const errors = data[key];
          if (typeof errors === "string") {
            value[key] = [errors];
            hasValue = true;
          } else if (utils.isArray(errors)) {
            value[key] = errors;
            hasValue = true;
          }
        }
      }
      if (hasValue) {
        this.value = data;
      }
    }

    if (data && data.message && this.value === null) {
      this.value = [`Ошибка выполнения запроса.`];
    }
    if (this.value === null) {
      this.value = ["Ошибка выполнения запроса"];
    }
  }

  getMessage(key, params) {
    if (utils.isPlainObject(key) && params === undefined) {
      params = key;
      key = null;
    }
    if (!utils.isPlainObject(params)) {
      params = {};
    }
    if (typeof params.separator !== "string") {
      params.separator = "<br /> ";
    }
    if (!utils.isArray(params.include)) {
      params.include = null;
    }
    if (!utils.isArray(params.exclude)) {
      params.exclude = null;
    }

    if (key) {
      if (this.isObject) {
        if (Object.prototype.hasOwnProperty.call(this.value, key)) {
          return this.value[key].join(params.separator);
        }
      }
      return null;
    }

    if (this.isArray) {
      let message = "";
      this.value.forEach((err) => {
        message = message + err.error_display_message;
      });
      return message;
      // return this.value.join(params.separator);
    } else if (this.isObject) {
      let message = [];
      for (let key in this.value) {
        if (Object.prototype.hasOwnProperty.call(this.value, key)) {
          if (params.include !== null) {
            if (params.include.indexOf(key) < 0) {
              continue;
            }
          }
          if (params.exclude !== null) {
            if (params.exclude.indexOf(key) >= 0) {
              continue;
            }
          }
          message.push(this.value[key]);
        }
      }
      if (message.length > 0) {
        return message.join(params.separator);
      }
    }
    return null;
  }
}

export default class ApiResponse {
  status = false;
  error = null;
  result = null;

  get isSuccess() {
    return this.status;
  }

  constructor(json) {
    if (json) {
      if (json.success) {
        if (json.result) {
          this.status = true;
          this.result = json.result;
        } else {
          this.status = true;
          this.result = json.success.response;
        }
      } else if (json.error) {
        if (json.error.response) {
          this.error = new ApiError(json.error.response);
        } else {
          this.error = new ApiError(json.error);
        }
      } else if (!json.success && !json.error) {
        this.status = true;
        this.result = json;
      } else {
        this.error = new ApiError(json);
      }
    } else {
      this.error = new ApiError();
    }
  }

  clone() {
    let response = new ApiResponse();
    response.status = this.status;
    response.error = this.error;
    response.result = this.result;
    return response;
  }

  static success(result) {
    return new ApiResponse({
      status: true,
      result: result ? result : null,
    });
  }

  static fail(error) {
    return new ApiResponse({
      status: false,
      error: new ApiResponse(error),
    });
  }
}
