import axios from "axios";
import api from "@/api";
import router from "@/router";

const state = {
  currentUser: null,
  isAuth: false,
  userID: null,
  userRole: null,
  error: null,
  token: null,
  showTerms: false,
};

const getters = {
  GET_CURRENT_USER(state) {
    return state.currentUser;
  },
  GET_IS_AUTH(state) {
    return state.isAuth;
  },
  GET_USER_ID(state) {
    return state.userID;
  },
  GET_USER_ROLE(state) {
    return state.userRole;
  },
  GET_ERROR(state) {
    return state.error;
  },
  GET_SHOW_TERMS(state) {
    return state.showTerms;
  },
};

const mutations = {
  SET_CURRENT_USER(state, payload) {
    state.currentUser = payload;
  },
  SET_IS_AUTH(state, payload) {
    localStorage.setItem("auth", JSON.stringify(payload));
    state.isAuth = JSON.parse(localStorage.getItem("auth"));
  },
  SET_USER_ID(state, payload) {
    localStorage.setItem("userID", JSON.stringify(payload));
    state.userID = JSON.parse(localStorage.getItem("userID"));
  },
  SET_USER_ROLE(state, payload) {
    localStorage.setItem("userRole", JSON.stringify(payload));
    state.userRole = JSON.parse(localStorage.getItem("userRole"));
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_TOKEN(state, token) {
    if (!token) {
      token = null;
    }
    state.token = token;
    if (token !== null) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Access-Token"] = token;
    } else {
      localStorage.removeItem("token");
      axios.defaults.headers.common["Access-Token"] = "";
    }
  },
  SET_SHOW_TERMS(state, payload) {
    state.showTerms = payload;
  },
};

const actions = {
  async login({ commit, dispatch }, payload) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    commit("SET_ERROR", null);
    let res = await api.user.login(payload);
    if (res.isSuccess) {
      commit("SET_TOKEN", res.result.access_token);
      commit("SET_IS_AUTH", true);
      commit("SET_USER_ID", res.result.user_id);
      if (res.result.promo_group.system_name) {
        commit("SET_USER_ROLE", res.result.promo_group.system_name);
      }
      dispatch("fetchUser");
    } else {
      commit("SET_ERROR", res.error.getMessage());
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async register({ commit }, payload) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    commit("SET_ERROR", null);
    let res = await api.user.register(payload);
    if (!res.isSuccess) {
      if (res.error.value[0].error_lang_alias[0] === "cts.company_service.collision_inn") {
        commit(
          "SET_ERROR",
          "Компания с указанным ИНН уже существует. Если данная ИНН принадлежит Вам, то необходимо обратиться в техническую поддержку liga@starateli.com."
        );
      } else if (
        res.error.value[0].error_lang_alias[0] === "cts.validation.validation_error" &&
        res.error.value[0].error_system_message["field_key"] === "phone"
      ) {
        commit("SET_ERROR", "Телефон должен состоять из 11 цифр.");
      } else {
        commit("SET_ERROR", res.error.getMessage());
      }
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async fetchUser({ commit, dispatch }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    commit("SET_SHOW_TERMS", false);
    let res = await api.user.getUserProfile();
    if (res.isSuccess) {
      commit("SET_CURRENT_USER", res.result);
      if (!res.result.is_agreement_accepted) {
        commit("SET_SHOW_TERMS", true);
      }
    } else {
      dispatch("logout");
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async logout({ commit, dispatch }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.user.logout();
    commit("SET_TOKEN", null);
    commit("SET_IS_AUTH", false);
    commit("SET_USER_ROLE", null);
    commit("SET_CURRENT_USER", null);
    localStorage.clear();
    router.push("/");
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    dispatch("initMainPage", null, { root: true });
    return res;
  },
  resetError({ commit }) {
    commit("SET_ERROR", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
