<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li v-for="(tab, index) in tabs" :key="tab.title" @click="selectTab(index)" :class="{ tab__selected: index == selectedIndex }">
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabContainer",
  components: {},
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `tabs_${this.layoutColor}`;
    },
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.tabs {
  width: 100%;
}

ul.tabs__header {
  display: flex;
  // display: block;
  list-style: none;
  padding: 0;
}

ul.tabs__header > li {
  padding: 14px 30px;
  border-radius: 10px 10px 0 0;
  margin: 0;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: $color-grey-50;
  background-color: #eeefef;
}

ul.tabs__header > li.tab__selected {
  background-color: $color-white;
  color: $color-red-20;
}

.tab {
  display: inline-block;
  width: 100%;
  border-radius: 0 20px 20px 20px;
  padding: 40px 35px;
  background-color: $color-white;
  color: $color-grey-50;

  &_modal {
    max-width: 640px;
    padding: 27px;
  }
}

@media screen and (max-width: 600px) {
  ul.tabs__header > li {
    padding: 10px;
    max-width: 155px;
    width: 100%;
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  ul.tabs__header > li {
    max-width: 120px;
    font-size: 12px;
  }
}
</style>
