import Vue from "vue";
import Vuetify, { VDataTable, VCard, VTextField, VPagination, VIcon } from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VDataTable,
    VCard,
    VTextField,
    VPagination,
    VIcon,
  },
});

const opts = {
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
};

export default new Vuetify(opts);
