import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import general from "./modules/general";
import content from "./modules/content";
import prize from "./modules/prize";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    preloader: 0,
  },
  getters: {
    GET_PRELOADER(state) {
      return state.preloader;
    },
  },
  mutations: {
    SET_PRELOADER_INCR(state) {
      state.preloader++;
    },
    SET_PRELOADER_DECR(state) {
      state.preloader--;
    },
  },
  actions: {
    async initMainPage({ commit, dispatch }) {
      if (localStorage.auth) {
        commit("user/SET_IS_AUTH", JSON.parse(localStorage.getItem("auth")));
        commit("user/SET_USER_ID", JSON.parse(localStorage.getItem("userID")));
        commit("user/SET_USER_ROLE", JSON.parse(localStorage.getItem("userRole")));
        dispatch("user/fetchUser");
      } else {
        // dispatch("content/getMenu");
        // dispatch("content/getSteps");
        dispatch("content/getRules");
        dispatch("content/getFAQ");
        // dispatch("content/getGifts");
      }
    },
    preloaderOn({ commit }) {
      commit("SET_PRELOADER_INCR");
    },
    preloaderOff({ commit }) {
      commit("SET_PRELOADER_DECR");
    },
  },
  modules: {
    user,
    general,
    content,
    prize,
  },
});
