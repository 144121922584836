<template>
  <div>
    <div class="modal__overlay" @click="$emit('hideModal')"></div>
    <div class="modal" :class="getSize">
      <div class="modal__header">
        <slot name="header" />
        <div class="modal__header_button" v-if="!hideClose">
          <MainButton :iconAfter="require('@/assets/img/close.svg')" @click="$emit('hideModal')" />
        </div>
      </div>
      <div class="modal__wrapper">
        <div class="modal__content">
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "ModalBox",
  components: {
    MainButton,
  },
  props: {
    size: {
      type: String,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSize() {
      return `modal_${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  max-width: 422px;
  width: 100%;
  max-height: 98vh;
  padding: 26px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: $transition-main;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &_large {
    max-width: 640px;
    width: 100%;
  }

  &_max {
    max-width: 90vw;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(16, 24, 32, 0.5);
    transition: $transition-main;
    z-index: 10;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &_button {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        transition: $transition-main;

        &:hover {
          transform: rotate(-180deg) scale(1.2);
          transition: $transition-main;
        }
      }
    }
  }

  &__wrapper {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 0 34px 34px 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-grey-50;

    &_title {
      color: $color-grey-50;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 700;
    }

    &_error {
      color: $color-red-20;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 20px;
    }

    &_warning {
      margin-bottom: 10px;
      color: #e61919;
      font-size: 16px;
      font-weight: 700;
    }

    &_image {
      max-width: 302px;
      width: 100%;
      // height: 270px;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 20px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_form {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &_sm {
        gap: 10px;
      }

      &_title {
        color: $color-grey-50;
        font-weight: 700;
        font-size: 16px;
      }

      &_country {
        & div {
          color: #abadad;
          font-size: 14px;
          line-height: 26px;
        }
      }

      &_inputs {
        display: flex;
        gap: 20px;
      }
    }

    &_info {
      margin-bottom: 24px;
    }

    &_message {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: $color-grey-50;
    }

    &_text {
      font-size: 16px;
      font-weight: 400;
      color: #37323d;
      margin-bottom: 5px;

      &_mb {
        margin-bottom: 20px;
      }
    }

    &_email {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: $color-red-20;

      &_mb {
        margin-bottom: 20px;
      }
    }

    &_button {
      width: 302px;
      height: 58px;

      &_center {
        align-self: center;
      }
    }

    &_success {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      margin-bottom: 24px;

      &_text {
        max-width: 238px;
        width: 100%;
        font-size: 20px;
        line-height: 26px;
        color: $color-grey-50;
        font-weight: 600;
        text-align: center;
      }
    }

    &_options {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    &_pdf {
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;

      &_link {
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    &_checkbox {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
    }

    &_otp {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 700px) {
  .modal {
    &_large {
      max-width: 480px;
    }

    &__content {
      &_form {
        &_inputs {
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .modal {
    &_large {
      max-width: 400px;
    }

    &__content {
      padding: 0;

      &_button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .modal {
    max-width: 400px;

    &_large {
      max-width: 360px;
    }
  }
}

@media screen and (max-width: 390px) {
  .modal {
    max-width: 340px;

    &_large {
      max-width: 340px;
    }
  }
}

@media screen and (max-width: 346px) {
  .modal {
    max-width: 312px;

    &_large {
      max-width: 312px;
    }
  }
}
</style>
