import api from "@/api";

const state = {
  innList: [],
  companyList: [],
  retailList: null,
  cityList: null,
  streetList: null,
};

const getters = {
  GET_INN_LIST(state) {
    return state.innList;
  },
  GET_COMPANY_LIST(state) {
    return state.companyList;
  },
  GET_RETAIL_LIST(state) {
    return state.retailList;
  },
  GET_CITY_LIST(state) {
    return state.cityList;
  },
  GET_STREET_LIST(state) {
    return state.streetList;
  },
};

const mutations = {
  SET_INN_LIST(state, payload) {
    state.innList = payload;
  },
  SET_COMPANY_LIST(state, payload) {
    state.companyList = payload;
  },
  SET_RETAIL_LIST(state, payload) {
    state.retailList = payload;
  },
  SET_CITY_LIST(state, payload) {
    state.cityList = payload;
  },
  SET_STREET_LIST(state, payload) {
    state.streetList = payload;
  },
};

const actions = {
  async findCompanyByInn({ commit }, payload) {
    let res = await api.general.getCompanyByTin(payload);
    if (res.isSuccess) {
      commit("SET_INN_LIST", res.result);
    }
    return res;
  },
  async findCompanyByName({ commit }, payload) {
    let res = await api.general.getCompanyByName(payload);
    if (res.isSuccess) {
      commit("SET_COMPANY_LIST", res.result);
    }
    return res;
  },
  async findRetailByInn({ commit }, payload) {
    let res = await api.general.getRetailByTin(payload);
    if (res.isSuccess) {
      commit("SET_RETAIL_LIST", res.result);
    } else {
      commit("SET_RETAIL_LIST", null);
    }
    return res;
  },
  async findCity({ commit }, payload) {
    let res = await api.general.getCityList(payload);
    if (res.isSuccess) {
      commit("SET_CITY_LIST", res.result.cities);
    }
    return res;
  },
  async findStreet({ commit }, payload) {
    let res = await api.general.getStreetList(payload);
    if (res.isSuccess) {
      commit("SET_STREET_LIST", res.result.streets);
    }
    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
