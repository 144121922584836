import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // PUBLIC
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Public/HomeView.vue"),
    meta: { requiresGuest: true },
  },
  {
    path: "/rules/",
    name: "Rules",
    component: () => import("@/views/Public/RulesView.vue"),
    meta: { requiresGuest: true },
  },
  {
    path: "/faq/",
    name: "FAQ",
    component: () => import("@/views/Public/FAQView.vue"),
    meta: { requiresGuest: true },
  },
  // {
  //   path: "/news/",
  //   name: "News",
  //   component: () => import("@/views/Public/NewsView.vue"),
  //   meta: { requiresGuest: true },
  // },
  // {
  //   path: "/news/:code/",
  //   name: "NewsDetail",
  //   props: true,
  //   component: () => import("@/views/Public/NewsDetail.vue"),
  //   meta: { requiresGuest: true },
  // },
  // DASHBOARD
  {
    path: "/dashboard/profile/",
    name: "Profile",
    component: () => import("@/views/Dashboard/ProfileView.vue"),
    meta: { requiresAuth: true, roles: ["curator", "manager", "member"] },
  },
  {
    path: "/dashboard/report/",
    name: "Report",
    component: () => import("@/views/Dashboard/ReportView.vue"),
    meta: { requiresAuth: true, roles: ["curator"] },
  },
  {
    path: "/dashboard/invite/",
    name: "Invite",
    component: () => import("@/views/Dashboard/InviteView.vue"),
    meta: { requiresAuth: true, roles: ["curator", "manager"] },
  },
  {
    path: "/dashboard/points/",
    name: "Points",
    component: () => import("@/views/Dashboard/PointsView.vue"),
    meta: { requiresAuth: true, roles: ["curator", "manager", "member"] },
  },
  {
    path: "/dashboard/prize/",
    name: "Prize",
    component: () => import("@/views/Dashboard/PrizeView.vue"),
    meta: { requiresAuth: true, roles: ["curator", "manager", "member"] },
  },
  {
    path: "/dashboard/purchase/",
    name: "Purchase",
    component: () => import("@/views/Dashboard/PurchaseView.vue"),
    meta: { requiresAuth: true, roles: ["member"] },
  },
  {
    path: "/dashboard/sales/",
    name: "Sales",
    component: () => import("@/views/Dashboard/SalesView.vue"),
    meta: { requiresAuth: true, roles: ["manager"] },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  const isAuth = localStorage.getItem("auth");
  const userRole = JSON.parse(localStorage.getItem("userRole"));
  if (requiresAuth && !isAuth) {
    next("/");
  } else if (requiresGuest && isAuth) {
    next("/dashboard/profile");
  } else if (requiresAuth && isAuth && to.path !== "/dashboard/profile" && !to.meta?.roles?.includes(userRole)) {
    next("/dashboard/profile");
  } else {
    next();
  }
});

export default router;
