<template>
  <TabModal :showBoth="showBoth" :role="role" @hideModal="$emit('hideModal')" @reset="onCaptchaExpired">
    <template #content-representative>
      <div class="tab-modal__content_title">Регистрация</div>
      <div class="tab-modal__content_subtitle">Клиент</div>
      <div v-if="error" class="tab-modal__content_error">
        {{ error }}
      </div>
      <div class="tab-modal__content_form" @input="checkForm" @change="checkForm">
        <div class="tab-modal__content_form_inputs">
          <InputSearch
            placeholder="ИНН"
            name="company_inn"
            id="company_inn"
            v-model="registerForm.company_inn"
            @input="findInn"
            @legalData="insertCompanyData"
          />
        </div>
        <div class="tab-modal__content_form_inputs">
          <MainInput
            type="text"
            placeholder="Юридический адрес"
            name="legalAddress"
            id="legalAddress"
            v-model="registerForm.legalAddress"
            :disabled="true"
          />
          <MainInput
            type="text"
            placeholder="Юр. наименование организации"
            name="legalName"
            id="legalName"
            v-model="registerForm.legalName"
            :disabled="true"
          />
        </div>
        <div class="tab-modal__content_form_inputs">
          <MainInput
            type="text"
            placeholder="Фактический адрес"
            name="company_actual_address_organization"
            id="company_actual_address_organization"
            required="true"
            v-model="registerForm.company_actual_address_organization"
          />
          <MainInput
            type="text"
            placeholder="Фактическое наименование"
            name="company_actual_name_organization"
            id="company_actual_name_organization"
            v-model="registerForm.company_actual_name_organization"
          />
        </div>
        <CheckBox name="same_address" text="Совпадает с юридическим" v-model="sameAddress" />
        <SelectInput name="segment" id="segment" placeholder="Сегмент*" :options="segments" v-model="registerForm.company_segment_id" />
        <div class="tab-modal__content_form_inputs">
          <MainInput type="text" placeholder="Фамилия*" name="last_name" id="last_name" required="true" v-model="registerForm.last_name" />
          <MainInput type="text" placeholder="Имя*" name="first_name" id="first_name" required="true" v-model="registerForm.first_name" />
        </div>
        <div class="tab-modal__content_form_inputs">
          <MainInput type="text" placeholder="Отчество*" name="sur_name" id="sur_name" required="true" v-model="registerForm.sur_name" />
          <MainInput
            type="tel"
            placeholder="Телефон*"
            name="phone"
            id="phone"
            required="true"
            v-model="registerForm.phone"
            :formatPhone="true"
          />
        </div>
        <div class="tab-modal__content_form_inputs">
          <MainInput type="email" placeholder="E-mail*" name="email" id="email" required="true" v-model="registerForm.email" />
          <MainInput
            type="text"
            placeholder="Должность"
            name="employee_position"
            id="employee_position"
            v-model="registerForm.employee_position"
          />
        </div>
        <SelectInput
          :isDisabled="!!referralInfo || !!inviteInfo"
          placeholder="Партнер*"
          name="curator"
          id="curator"
          v-model="registerForm.curator"
          :options="curators"
        />
        <MainInput
          type="number"
          placeholder="Ваш код Репсли у данного Партнера*"
          name="repsli"
          id="repsli"
          v-mask="'##########'"
          v-model="registerForm.repsli_code"
        />
        <div class="tab-modal__content_form_inputs text">
          * Поле обязательно для заполнения Клиентам из Москвы и МО (без него не будут начислены баллы за покупку). Другим регионам не
          обязателен для заполнения.
        </div>
        <div v-if="!referralInfo && !inviteInfo" class="tab-modal__content_form_inputs">
          <MainInput
            type="text"
            placeholder="Телефон менеджера"
            name="manager"
            id="manager"
            v-model="registerForm.manager"
            :formatPhone="true"
            @input="checkPhoneNum"
          />
          <div class="tab-modal__content_form_button">
            <MainButton title="Проверить" color="transparent-red" @click="findManagerPhone" />
          </div>
        </div>
        <CheckBox
          v-if="!referralInfo && !inviteInfo"
          name="agreementMember"
          v-model="agreement"
          text="Согласен зарегистрироваться без менеджера по продажам Партнёра"
        />
        <div class="tab-modal__content_notification">
          <p>Способ получения уведомлений:</p>
          <CheckBox name="notificationRetail" v-model="emailNotification" text="Электронная почта" />
        </div>
        <div v-if="sitekey" class="tab-modal__content_recaptcha">
          <VueRecaptcha
            ref="memberRecaptcha"
            :size="recaptchaSize"
            :load-recaptcha-script="false"
            :sitekey="sitekey"
            @verify="setRecaptchaToken"
            @expired="onCaptchaExpired"
          />
        </div>
      </div>
      <div class="tab-modal__content_button tab-modal__content_button_center">
        <MainButton title="Зарегистрироваться" color="red" :isDisabled="disableRepresentativeBtn" @click="registerMember" />
      </div>
    </template>
    <template #content-sales>
      <div class="tab-modal__content_title">Регистрация</div>
      <div class="tab-modal__content_subtitle">Менеджер по продажам</div>
      <div v-if="error" class="tab-modal__content_error">
        {{ error }}
      </div>
      <div class="tab-modal__content_form" @input="checkForm" @change="checkForm">
        <SelectInput
          v-if="!inviteInfo && !referralInfo"
          placeholder="Партнер*"
          name="partner"
          id="partner"
          v-model="registerForm.related_to_user_id"
          :options="curators"
        />
        <div class="tab-modal__content_form_inputs">
          <MainInput type="text" name="last_name" id="last_name" v-model="registerForm.last_name" placeholder="Фамилия*" required="true" />
          <MainInput type="text" placeholder="Имя*" name="first_name" id="first_name" v-model="registerForm.first_name" required="true" />
        </div>
        <div class="tab-modal__content_form_inputs">
          <MainInput type="text" placeholder="Отчество*" name="sur_name" id="sur_name" v-model="registerForm.sur_name" required="true" />
          <MainInput
            type="tel"
            placeholder="Телефон*"
            name="phone"
            id="phone"
            v-model="registerForm.phone"
            required="true"
            :formatPhone="true"
          />
        </div>
        <div class="tab-modal__content_form_inputs">
          <div class="tab-modal__content_form_input">
            <MainInput type="email" placeholder="E-mail*" name="email" id="email" v-model="registerForm.email" required="true" />
          </div>
        </div>
        <div class="tab-modal__content_notification">
          <p>Способ получения уведомлений:</p>
          <CheckBox name="notificationSales" v-model="emailNotification" text="Электронная почта" />
        </div>
        <div v-if="sitekey" class="tab-modal__content_recaptcha">
          <VueRecaptcha
            ref="managerRecaptcha"
            :size="recaptchaSize"
            :load-recaptcha-script="false"
            :sitekey="sitekey"
            @verify="setRecaptchaToken"
            @expired="onCaptchaExpired"
          />
        </div>
      </div>
      <div class="tab-modal__content_button tab-modal__content_button_center">
        <MainButton title="Зарегистрироваться" color="red" :isDisabled="disableSalesBtn" @click="registerManager" />
      </div>
    </template>
  </TabModal>
</template>

<script>
import TabModal from "@/components/template/TabModal.vue";
import MainInput from "@/components/ui/Input/MainInput.vue";
import CheckBox from "@/components/ui/Checkbox/CheckBox.vue";
import SelectInput from "@/components/ui/Select/SelectInput.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import InputSearch from "@/components/ui/Input/InputSearch.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "RegisterForm",
  components: {
    TabModal,
    MainInput,
    CheckBox,
    SelectInput,
    MainButton,
    InputSearch,
    VueRecaptcha,
  },
  props: {
    inviteInfo: Object,
    referralInfo: Object,
    inviteInfoID: [String, Number],
    showBoth: {
      type: Boolean,
      default: false,
    },
    role: String,
  },
  data() {
    return {
      registerForm: {
        company_inn: null,
        repsli_code: null,
        legalName: null,
        legalAddress: null,
        company_segment_id: null,
        company_actual_name_organization: null,
        company_actual_address_organization: null,
        first_name: null,
        last_name: null,
        sur_name: null,
        phone: null,
        email: null,
        employee_position: null,
        manager: null,
        managerID: null,
        curator: null,
        user_role: null,
        related_to_user_id: null,
        recaptchaToken: null,
      },
      sameAddress: false,
      agreement: false,
      emailNotification: false,
      smsNotification: false,
      timer: undefined,
      segments: [],
      curators: [],
      sitekey: null,
    };
  },
  mounted() {
    this.init();
    this.fillForm();
  },
  watch: {
    sameAddress: function () {
      if (this.sameAddress === true) {
        this.registerForm.company_actual_address_organization = this.registerForm.legalAddress;
        document.getElementById("company_actual_address_organization").classList.remove("input__field_disable");
      } else {
        this.registerForm.company_actual_address_organization = null;
        document.getElementById("company_actual_address_organization").classList.add("input__field_disable");
      }
    },
    agreement: function () {
      if (this.agreement) {
        this.registerForm.manager = null;
        this.registerForm.managerID = null;
      }
      this.checkPhoneNum();
    },
  },
  computed: {
    error() {
      return this.$store.getters["user/GET_ERROR"];
    },
    disableRepresentativeBtn() {
      if (
        !this.registerForm.company_segment_id ||
        (!this.registerForm.related_to_user_id && !this.registerForm.curator) ||
        (!this.agreement && !this.registerForm.manager && !this.registerForm.related_to_user_id) ||
        !this.registerForm.company_inn ||
        // !this.registerForm.repsli_code ||
        // (this.registerForm.repsli_code && this.registerForm.repsli_code.length < 10) ||
        !this.registerForm.company_actual_address_organization ||
        !this.registerForm.first_name ||
        !this.registerForm.last_name ||
        !this.registerForm.sur_name ||
        !this.registerForm.email ||
        !this.registerForm.phone ||
        !this.registerForm.recaptchaToken
      ) {
        return true;
      }
      return false;
    },
    disableSalesBtn() {
      return !this.registerForm.related_to_user_id || !this.registerForm.recaptchaToken;
    },
    recaptchaSize() {
      if (window.innerWidth < 500) return "compact";
      return "normal";
    },
  },
  methods: {
    async init() {
      let segments = await this.$api.general.getSegments();
      segments.result.forEach((segment) => {
        this.segments.push({
          id: segment.id,
          name: segment.display_name,
        });
      });
      let curators = await this.$api.general.getCompaniesList();
      curators.result.forEach((curator) => {
        this.curators.push({
          id: curator.curator_id,
          name: curator.actual_name_organization,
        });
      });

      let res = await this.$api.user.fetchCaptchaKey();
      if (res.isSuccess) this.sitekey = res.result.sitekey;
    },
    fillForm() {
      if (this.inviteInfo) {
        this.registerForm.email = this.inviteInfo.email;
        this.registerForm.first_name = this.inviteInfo.first_name;
        this.registerForm.last_name = this.inviteInfo.last_name;
        this.registerForm.sur_name = this.inviteInfo.sur_name;
        this.registerForm.user_role = this.inviteInfo.target_promo_group_system_name;
        this.registerForm.related_to_user_id = this.inviteInfo.user_id;
        this.registerForm.curator = this.inviteInfo.curator_id;
      } else if (this.referralInfo) {
        this.registerForm.related_to_user_id = this.referralInfo.id;
        this.registerForm.curator = this.referralInfo.id;
      }
    },
    async registerMember() {
      let phoneClean = this.registerForm.phone.replace(/[^A-Z0-9]/gi, "");
      let payload = {
        user_role: this.registerForm.user_role || "member",
        company_segment_id: this.registerForm.company_segment_id,
        company_actual_name_organization: this.registerForm.company_actual_name_organization,
        company_actual_address_organization: this.registerForm.company_actual_address_organization,
        company_inn: this.registerForm.company_inn,
        repsli_code: this.registerForm.repsli_code || 0,
        phone: phoneClean,
        email: this.registerForm.email,
        first_name: this.registerForm.first_name,
        last_name: this.registerForm.last_name,
        sur_name: this.registerForm.sur_name,
        employee_position: this.registerForm.employee_position,
        email_notifications: this.emailNotification ? 1 : 0,
        is_allow_email_notify: this.emailNotification ? 1 : 0,
        sms_notifications: this.smsNotification ? 1 : 0,
        "g-recaptcha-response": this.registerForm.recaptchaToken,
      };
      if (this.registerForm.manager) {
        payload["related_to_user_id"] = this.registerForm.managerID;
      } else {
        payload["related_to_user_id"] = this.registerForm.curator;
      }
      if (this.inviteInfoID) {
        payload["invite_id"] = this.inviteInfoID;
        payload["related_to_user_id"] = this.registerForm.related_to_user_id;
      }
      if (this.referralInfo) {
        payload["related_to_user_id"] = this.registerForm.related_to_user_id;
        payload["referral_from_user_id"] = this.registerForm.related_to_user_id;
      }
      await this.$store.dispatch("user/register", payload);
      if (this.error === null) {
        this.$emit("toActivation", payload);
        this.resetForm();
      } else {
        this.onCaptchaExpired();
      }
    },
    async registerManager() {
      let phoneClean = this.registerForm.phone.replace(/[^A-Z0-9]/gi, "");
      let payload = {
        user_role: this.registerForm.user_role || "manager",
        phone: phoneClean,
        email: this.registerForm.email,
        first_name: this.registerForm.first_name,
        last_name: this.registerForm.last_name,
        sur_name: this.registerForm.sur_name,
        related_to_user_id: this.registerForm.related_to_user_id,
        email_notifications: this.emailNotification ? 1 : 0,
        is_allow_email_notify: this.emailNotification ? 1 : 0,
        sms_notifications: this.smsNotification ? 1 : 0,
        "g-recaptcha-response": this.registerForm.recaptchaToken,
      };
      if (this.inviteInfoID) {
        payload["invite_id"] = this.inviteInfoID;
      }
      if (this.referralInfo) {
        payload["referral_from_user_id"] = this.registerForm.related_to_user_id;
      }
      await this.$store.dispatch("user/register", payload);
      if (this.error === null) {
        this.$emit("toActivation", payload);
        this.resetForm();
      } else {
        this.onCaptchaExpired();
      }
    },
    findInn() {
      let inn = this.registerForm.company_inn;
      if (inn) {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.$store.dispatch("general/findCompanyByInn", inn);
        }, 500);
      }
    },
    insertCompanyData(args) {
      this.registerForm.legalName = args.name;
      this.registerForm.legalAddress = args.address;
    },
    checkPhoneNum() {
      let managerPhone = document.getElementById("manager");
      let reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
      if (reg.test(this.registerForm.manager)) {
        managerPhone.classList.remove("input__field_disable");
        return false;
      } else {
        if (this.agreement) {
          managerPhone.classList.remove("input__field_success");
          managerPhone.classList.remove("input__field_disable");
          return true;
        } else {
          managerPhone.classList.remove("input__field_success");
          managerPhone.classList.add("input__field_disable");
          return true;
        }
      }
    },
    async findManagerPhone() {
      let phoneClean = this.registerForm.manager.replace(/[^A-Z0-9]/gi, "");
      let phoneInput = document.getElementById("manager");
      let res = await this.$api.general.getManagerByPhone(phoneClean);
      if (res.isSuccess) {
        this.registerForm.managerID = res.result.id;
        phoneInput.classList.remove("input__field_disable");
        phoneInput.classList.add("input__field_success");
      } else {
        phoneInput.classList.remove("input__field_disable");
        phoneInput.classList.add("input__field_disable");
        this.agreement = true;
      }
    },
    checkForm() {
      let inputs = document.querySelectorAll(".input__field");
      inputs.forEach((input) => {
        if (input.hasAttribute("required")) {
          let id = input.getAttribute("id");
          let reg = /^(?!\s*$).+/;
          if (id === "phone") {
            reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
          } else if (id === "email") {
            // eslint-disable-next-line no-useless-escape
            reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
          } else if (id === "company_inn") {
            reg = /^([0-9]{10}|[0-9]{12})$/;
          }
          if (reg.test(input.value)) {
            input.classList.remove("input__field_disable");
          } else {
            input.classList.add("input__field_disable");
          }
        }
      });
      let tin = document.getElementById("company_inn");
      if (tin) {
        if (this.registerForm.company_inn === null) {
          tin.classList.add("input-search__field_disable");
        } else {
          tin.classList.remove("input-search__field_disable");
        }
      }
      let segment = document.getElementById("segment");
      if (segment) {
        if (this.registerForm.company_segment_id === null) {
          segment.classList.add("select_error");
        } else {
          segment.classList.remove("select_error");
        }
      }
      let curator = document.getElementById("curator");
      if (curator) {
        if (this.registerForm.curator === null) {
          curator.classList.add("select_error");
        } else {
          curator.classList.remove("select_error");
        }
      }
      let partner = document.getElementById("partner");
      if (partner) {
        if (this.registerForm.related_to_user_id === null) {
          partner.classList.add("select_error");
        } else {
          partner.classList.remove("select_error");
        }
      }
    },
    resetForm() {
      this.registerForm.company_inn =
        this.registerForm.legalName =
        this.registerForm.legalAddress =
        this.registerForm.repsli_code =
        this.registerForm.company_segment_id =
          ``;
      this.registerForm.segmentManual =
        this.registerForm.company_actual_name_organization =
        this.registerForm.company_actual_address_organization =
        this.registerForm.first_name =
        this.registerForm.last_name =
        this.registerForm.sur_name =
        this.registerForm.phone =
        this.registerForm.email =
        this.registerForm.employee_position =
        this.registerForm.manager =
        this.registerForm.curator =
        this.registerForm.user_role =
          null;
      this.sameAddress = this.agreement = this.notification = false;

      this.onCaptchaExpired();
    },
    onCaptchaExpired() {
      if (this.sitekey) {
        this.$refs.memberRecaptcha.reset();
        this.$refs.managerRecaptcha.reset();
        this.registerForm.recaptchaToken = null;
      }
    },
    setRecaptchaToken(recaptchaToken) {
      this.registerForm.recaptchaToken = recaptchaToken;
    },
  },
};
</script>
