<template>
  <div class="cookies">
    <div class="cookies__banner">
      <div class="cookies__banner_text">
        Используя данный сайт, я выражаю свое согласие на <a :href="url" target="_blank">обработку моих персональных данных</a>.
      </div>
      <div class="cookies__banner_button">
        <MainButton title="Принять" color="red" @click="$emit('acceptCookies')" />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "CookieModal",
  components: {
    MainButton,
  },
  computed: {
    url() {
      return "//liga.starateli.ru/upload/static/politika_konfidencialnosti.pdf";
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10%;
  left: 50%;
  z-index: 1000;
  color: $color-black;

  &__banner {
    position: fixed;
    max-width: 800px;
    width: 100%;
    padding: 20px 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    &_text {
      width: 80%;
      font-size: 14px;
      font-weight: 400;

      & a {
        color: $color-red-20;
      }
    }

    &_button {
      width: 150px;

      & .button {
        height: 48px;
      }
    }
  }
}
</style>
