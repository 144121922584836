<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_title">
        {{ title }}
      </div>
      <div class="modal__content_text modal__content_text_mb">
        {{ text }}
      </div>
      <div v-if="email" class="modal__content_email modal__content_email_mb">
        {{ email }}
      </div>
      <div class="modal__content_button modal__content_button_center">
        <MainButton title="ОК" color="red" @click="$emit('hideModal')" />
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "ModalWarning",
  components: {
    ModalBox,
    MainButton,
  },
  props: {
    title: String,
    text: String,
    email: String,
  },
};
</script>
