import network from "@/api/network";

export default class Transactions {
  static async getPoints() {
    return await network.get("user/points", null, {});
  }

  static async getDeals() {
    return await network.get("deals/current/user", null, {});
  }

  static async exportDeals(config) {
    return await network.get("deals/current/user/export", null, config);
  }
}
