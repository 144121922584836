<template>
  <button class="button" :class="[getColor, disabled]" @click="sendEvent">
    <img v-if="iconBefore" class="button__icon_before" :src="iconBefore" />
    <div class="button__box">
      <p v-if="title" class="button__title">
        {{ title }}
      </p>
      <div v-if="disabledText && isDisabled" class="button__disabled_text">
        {{ disabledText }}
      </div>
    </div>
    <img v-if="iconAfter" class="button__icon_after" :src="iconAfter" />
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    iconBefore: String,
    iconAfter: String,
    color: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    disabledText: String,
  },
  computed: {
    getColor() {
      return `button_${this.color}`;
    },
    disabled() {
      return this.isDisabled ? "button__disabled" : "";
    },
  },
  methods: {
    sendEvent() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: $transition-main;
  font-family: inherit;

  &_red {
    color: $color-white;
    background-color: $color-red-20;
    font-weight: 400;
    font-size: 17px;

    &:hover {
      background-color: $color-red-10;
    }
  }

  &_transparent-red {
    color: $color-red-20;
    background-color: transparent;
    border: 1px solid $color-red-20;
    font-weight: 400;
    font-size: 17px;

    &:hover {
      color: $color-white;
      background-color: $color-red-20;
    }
  }

  &_white {
    background-color: $color-white;
    color: $color-red-20;
    font-weight: 400;
    font-size: 17px;

    &:hover {
      color: $color-white;
      background-color: $color-red-10;

      & .button__icon_before {
        filter: brightness(0) invert(1);
      }
    }
  }

  &_white-border {
    background-color: $color-white;
    color: $color-black;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #eeefef;
  }

  &_grey {
    background-color: #f2f2f2;
    color: $color-black;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #eeefef;
  }

  &_transparent {
    background-color: transparent;
    color: $color-grey-40;
    font-weight: 400;
    font-size: 14px;
    border: none;

    &:hover {
      color: $color-red-20;
    }
  }

  &__icon {
    &_before {
      transition: $transition-main;
      margin-right: 8px;
    }
  }

  &__disabled {
    opacity: 50%;
    pointer-events: none;

    &_text {
      color: $color-red-10;
      font-size: 12px;
      font-weight: 400;
    }

    & .button__title {
      text-align: start;
    }

    & .button__disabled_text {
      text-align: start;
    }
  }
}
</style>
