<template>
  <div class="select" :class="[getShowOptions, getIsDisabled]" :id="id" @click="toggleShowOptions">
    <div class="select__result_wrap">
      <p v-if="selectedOption" class="select__result">
        {{ selectedOption }}
      </p>
      <p v-else class="select__result_placeholder">
        {{ placeholder }}
      </p>
    </div>
    <div class="select__options" :class="getSize">
      <label class="select__option" v-for="item in options" :value="item.name" :key="item.id">
        <input
          class="select__input select__input_radio"
          type="radio"
          name="select"
          :id="id"
          :value="item.name"
          :required="required"
          @click="toggleShowOptions"
          @change="emitItem(item)"
        />
        <span class="select__result">
          {{ item.name }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    options: Array,
    placeholder: String,
    required: String,
    id: String,
    value: [String, Number],
    isSmall: Boolean,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOptions: false,
      selectedOption: null,
    };
  },
  created() {
    window.addEventListener("click", this.close);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.selectedOption = null;
      }
    },
    options: function () {
      if (this.value) {
        let result = this.options.find((option) => option.id === this.value);
        this.selectedOption = result.name;
      }
    },
  },
  computed: {
    getSize() {
      return this.isSmall ? "select__options_small" : "";
    },
    getShowOptions() {
      return this.showOptions ? "select__options_show" : "";
    },
    getIsDisabled() {
      return this.isDisabled ? "select_disabled" : "";
    },
  },
  methods: {
    toggleShowOptions() {
      this.showOptions = !this.showOptions;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showOptions = false;
      }
    },
    emitItem(item) {
      if (
        this.id === "sum" ||
        this.id === "curator" ||
        this.id === "partner" ||
        this.id === "certificate" ||
        this.id === "segment" ||
        this.id === "type"
      ) {
        this.selectedOption = item.name;
        this.$emit("input", item.id);
      } else if (this.id === "nominal") {
        this.selectedOption = item.name;
        this.$emit("nominalValue", { id: item.id, value: item.value });
      } else if (this.id === "segmentModal") {
        this.selectedOption = item.name;
        this.$emit("input", item.name);
        this.$emit("change");
      } else {
        this.selectedOption = item.name;
        this.$emit("input", item.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #e2e3e3;

  &::before {
    content: "";
    position: absolute;
    display: flex;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: url("~@/assets/img/select.svg") no-repeat center center;
    width: 14px;
    height: 14px;
    transition: $transition-main;
    cursor: pointer;
  }

  &__options {
    transition: $transition-main;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50px;
    width: 100%;
    max-height: 328px;
    overflow-y: scroll;
    background-color: $color-white;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    z-index: 4;

    &-small {
      max-height: 100px;
    }
  }

  &__options_show {
    &::before {
      transform: rotate(-180deg) translateY(50%);
    }

    .select__options {
      opacity: 1;
      visibility: visible;
    }
  }

  &__option {
    position: relative;
    padding-left: 12px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      color: $color-red-20;
    }
  }

  &__input_radio {
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }

  &__result {
    font-size: 14px;
    font-weight: 400;

    &_placeholder {
      color: #abadad;
    }
  }

  &__result_wrap {
    display: flex;
    width: 100%;
    padding-left: 12px;
    padding-right: 30px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
  }

  &_disabled {
    pointer-events: none;

    &::before {
      background: url("~@/assets/img/select-grey.svg") no-repeat center center;
    }

    & .select__result {
      color: #abadad;
    }
  }

  &_error {
    border: 1px solid $color-red-10;

    &:focus {
      border: 1px solid $color-red-10;
    }
  }
}
</style>
