<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_title">Напишите нам</div>
      <div v-if="error" class="modal__content_error">
        {{ error }}
      </div>
      <div class="modal__content_form modal__content_form_sm">
        <MainInput type="text" placeholder="ФИО" name="name" id="name" v-model="feedbackForm.name" @input="checkForm" />
        <MainInput type="email" placeholder="E-mail" name="email" id="email" v-model="feedbackForm.email" @input="checkForm" />
        <TextArea name="message" placeholder="Введите сообщение" v-model="feedbackForm.message" @input="checkForm" />
      </div>
      <div class="modal__content_button">
        <MainButton title="Отправить" color="red" :isDisabled="disableButton" @click="sendFeedback" />
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import MainInput from "@/components/ui/Input/MainInput.vue";
import TextArea from "@/components/ui/Input/TextArea.vue";

export default {
  name: "ModalFeedback",
  components: {
    ModalBox,
    MainButton,
    MainInput,
    TextArea,
  },
  data() {
    return {
      feedbackForm: {
        name: null,
        email: null,
        message: null,
      },
      error: null,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
    disableButton() {
      if (this.feedbackForm.name && this.feedbackForm.email && this.feedbackForm.message) {
        return false;
      }
      return true;
    },
  },
  watch: {
    userProfile: function () {
      this.fillFeedbackForm();
    },
  },
  methods: {
    fillFeedbackForm() {
      if (this.userProfile) {
        this.feedbackForm.name = `${this.userProfile.last_name || ""} ${this.userProfile.first_name || ""} ${
          this.userProfile.sur_name || ""
        }`;
        this.feedbackForm.email = this.userProfile.email;
      } else {
        this.feedbackForm.name = this.feedbackForm.email = null;
      }
    },
    checkForm() {
      let inputs = document.querySelectorAll(".input__field");
      let textarea = document.querySelector(".textarea__field");
      inputs.forEach((input) => {
        let id = input.getAttribute("id");
        let reg = /^(?!\s*$).+/;
        if (id === "email") {
          // eslint-disable-next-line no-useless-escape
          reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
        }
        if (reg.test(input.value)) {
          input.classList.remove("input__field_disable");
        } else {
          input.classList.add("input__field_disable");
        }
      });
      let reg = /^(?!\s*$).+/;
      if (reg.test(textarea.value)) {
        textarea.classList.remove("textarea__field_disable");
      } else {
        textarea.classList.add("textarea__field_disable");
      }
    },
    resetForm() {
      this.feedbackForm.name = this.feedbackForm.email = this.feedbackForm.message = null;
    },
    async sendFeedback() {
      let payload = this.feedbackForm;
      let res = await this.$api.content.sendFeedback(payload);
      if (res.isSuccess) {
        this.$emit("hideModal");
        this.$emit("showSuccess");
        this.resetForm();
      } else {
        this.error = res.error.getMessage();
      }
    },
  },
};
</script>
