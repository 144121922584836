import network from "@/api/network";

export default class Prize {
  static async getPrizeList() {
    return await network.get("prize/list", null, {});
  }

  static async orderPrize(data) {
    return await network.post("prize/exchange", data, {});
  }

  static async getCharacteristics() {
    return await network.get("prize/characteristics/list", null, {});
  }

  static async uploadPersonalData(data) {
    return await network.post("user/profile/add/documents", data, {});
  }

  // Ю КАССА
  static async getAgentId() {
    return await network.get("payments/agentId", null, {});
  }
}
