import network from "@/api/network";

export default class Reports {
  static async getReportList() {
    return await network.get("deals/report/list", null, {});
  }

  static async downloadReport(month, year, config) {
    return await network.get(`deals/report/download?sell_month=${month}&sell_year=${year}`, null, config);
  }

  static async uploadCuratorReport(data) {
    return await network.post("deals/report/import", data, {});
  }

  static async exportTinList(config) {
    return await network.get("user/profile/curator/export/inn/members", null, config);
  }
}
