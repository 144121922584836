<template>
  <div class="tab" v-show="isActive" :class="getStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabItem",
  props: {
    title: {
      type: String,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
  computed: {
    getStyle() {
      return this.isModal ? "tab_modal" : "";
    },
  },
};
</script>
