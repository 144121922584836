<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_title">Восстановление пароля</div>
      <div v-if="error" class="modal__content_error">
        {{ error }}
      </div>
      <div v-if="checkword && email" class="modal__content_form modal__content_form_sm">
        <MainInput type="password" name="password" id="password" v-model="changePasswordForm.password" placeholder="Новый пароль" />
        <MainInput
          type="password"
          name="confirm_password"
          id="confirm_password"
          v-model="changePasswordForm.confirm_password"
          placeholder="Повторите пароль"
        />
      </div>
      <div v-else class="modal__content_form modal__content_form_sm">
        <MainInput type="email" placeholder="E-mail" name="email" id="email" v-model="passwordForm.email" @input="checkEmail" />
      </div>
      <div class="modal__content_button">
        <MainButton v-if="checkword && email" title="Восстановить" color="red" :isDisabled="disableButton" @click="changePassword" />
        <MainButton v-else title="Восстановить" color="red" :isDisabled="!passwordForm.email" @click="recover" />
      </div>
      <div class="modal__content_options">
        <div>
          <MainButton title="Авторизация" color="transparent" @click="$emit('toLogin')" />
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import MainInput from "@/components/ui/Input/MainInput.vue";

export default {
  name: "LoginForm",
  components: {
    ModalBox,
    MainButton,
    MainInput,
  },
  props: {
    checkword: String,
    email: String,
  },
  data() {
    return {
      error: null,
      passwordForm: {
        email: null,
      },
      changePasswordForm: {
        password: null,
        confirm_password: null,
      },
    };
  },
  computed: {
    disableButton() {
      if (
        this.changePasswordForm.password &&
        this.changePasswordForm.confirm_password &&
        this.changePasswordForm.password.length >= 9 &&
        this.changePasswordForm.confirm_password.length >= 9 &&
        this.changePasswordForm.password === this.changePasswordForm.confirm_password
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async recover() {
      this.error = null;
      let emailInput = document.getElementById("email");
      let payload = { login: this.passwordForm.email };
      let res = await this.$api.user.recoverPassword(payload);
      if (res.isSuccess) {
        this.$emit("showSuccess");
        this.resetForm();
        emailInput.classList.remove("input__field_disable");
      } else {
        this.error = res.error.getMessage();
        emailInput.classList.add("input__field_disable");
      }
    },
    async changePassword() {
      this.error = null;
      let payload = {
        check_word: this.checkword,
        email: this.email,
        new_password: this.changePasswordForm.confirm_password,
      };
      let res = await this.$api.user.changePassword(payload);
      if (res.isSuccess) {
        this.resetForm();
        this.$emit("hideModal");
      } else {
        this.error = res.error.getMessage();
      }
    },
    checkEmail(value) {
      let emailInput = document.getElementById("email");
      // eslint-disable-next-line no-useless-escape
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
      if (reg.test(value)) {
        emailInput.classList.remove("input__field_disable");
      } else {
        emailInput.classList.add("input__field_disable");
      }
    },
    resetForm() {
      this.error = null;
      this.passwordForm.email = null;
      this.changePasswordForm.password = this.changePasswordForm.confirm_password = null;
    },
  },
};
</script>
