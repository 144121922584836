<template>
  <div class="textarea">
    <textarea
      class="textarea__field"
      :name="name"
      :placeholder="placeholder"
      v-model="valueInput"
      ref="textarea"
      @input="emitValue(valueInput)"
    ></textarea>
    <!-- <div class="textarea__label">
      {{ placeholder }}
      <span v-if="marker" class="textarea__marker">
        {{ marker }}
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    name: String,
    placeholder: String,
    marker: String,
    value: String,
  },
  data() {
    return {
      valueInput: "",
    };
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.valueInput = "";
      }
    },
  },
  methods: {
    emitValue() {
      let textarea = this.$refs.textarea;
      this.$emit("input", textarea.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  position: relative;

  &__field {
    width: 100%;
    height: 134px;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    resize: none;
    border: 1px solid #e2e3e3;
    color: $color-grey-50;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #abadad;
    }

    &:focus + .textarea__label {
      display: none;
    }

    &:not(:placeholder-shown) + .textarea__label {
      display: none;
    }

    &_disable {
      border: 1px solid $color-red-10;

      &:focus {
        border: 1px solid $color-red-10;
      }
    }
  }
}
</style>
