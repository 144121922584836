<template>
  <div class="input">
    <label class="input__label" :for="name" v-if="titleLabel">
      {{ titleLabel }}
    </label>
    <input
      v-if="name === 'passport_issued_at' || name === 'date_of_birth'"
      class="input__field"
      :type="type"
      :placeholder="placeholder"
      :id="id"
      :name="name"
      :value="value"
      :required="required"
      v-model="valueInput"
      ref="input"
      @input="emitValue(valueInput)"
      onfocus="(this.type='date')"
      onblur="(this.type='text')"
      lang="ru-Ru"
    />
    <input
      v-else
      class="input__field"
      :type="type"
      :placeholder="placeholder"
      :id="id"
      :name="name"
      :value="value"
      :required="required"
      :disabled="disabled"
      v-model="valueInput"
      ref="input"
      @input="emitValue(valueInput)"
    />
  </div>
</template>

<script>
export default {
  name: "MainInput",
  props: {
    titleLabel: String,
    type: {
      type: String,
      default: "text",
    },
    placeholder: String,
    name: String,
    value: String,
    required: String,
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    formatPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueInput: "",
    };
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.valueInput = "";
      } else {
        this.valueInput = this.value;
      }
    },
  },
  methods: {
    emitValue() {
      let input = this.$refs.input;
      if (this.formatPhone) {
        let blank = "+_ (___) ___-__-__";
        let i = 0;
        let val;
        if (input.value[0] === "9") {
          val = input.value.replace(/\D/g, "").replace(/^/, "7");
        } else {
          val = input.value.replace(/\D/g, "").replace(/^\d/, "7");
        }
        this.valueInput = blank.replace(/./g, function (char) {
          if (/[_\d]/.test(char) && i < val.length) return val.charAt(i++);

          return i >= val.length ? "" : char;
        });
        this.$emit("input", this.valueInput);
      } else {
        this.$emit("input", input.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;

  &__label {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    color: $color-grey-50;
  }

  &__field {
    width: 100%;
    height: 48px;
    border: 1px solid #e2e3e3;
    color: $color-grey-50;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #abadad;
    }

    &_disable {
      border: 1px solid $color-red-10;

      &:focus {
        border: 1px solid $color-red-10;
      }
    }

    &_success {
      border: 1px solid #278d72;

      &:focus {
        border: 1px solid #278d72;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
